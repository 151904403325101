*{margin:0;padding:0;box-sizing:border-box;}

.BioTitle{
  color:red;  
}
.project{
  opacity:0;
  transition:opacity 2s ease-in-out;
  animation:showIN 2s ease-in-out;
}

@keyframes showIN {
  from {opacity:0;transform:translateX(-100%);}
  to {opacity:1;transform:translateX(0%);}
}
.sidebar {
  background:grey !important;
  color:black !important;
  margin-left:0px !important;
  animation: slideIn 2.5s ease-in-out;
}
@keyframes slideIn {
  from {transform:translateX(-50%);}
  to { transform:translateX(0%);}
}
@media screen and (max-width:600px){
  .sidebar {
    animation: slideIn 3.5s ease-in-out;
  }
}