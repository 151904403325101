*{margin:0;padding:0;box-sizing:border-box}

/* -----------------------------COVERPAGE START ------------------*/
.custCoverPage h1,.custCoverPage p{
    font-family:var(--font-family);
}
.custCoverPage >div{
    margin:auto;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    animation:slideIn 3s ease-in-out;
    
}
.custCoverPage >div >p{
    text-align:center;
}
@keyframes slideIn{
    from {opacity:0; transform:translateX(-100%);}
    to { opacity:1; transform:translateX(0%);}
}
@keyframes slideDown{
    from {opacity:0; transform:translateY(-300%);}
    to { opacity:1; transform:translateY(0%);}
}
.fontStyleCoverPage{
    margin:auto;
    font-size:1000%;
    color:transparent;
    background-clip:text;
    -webkit-background-clip:text;
    -webkit-text-stroke:2px black;
    background-position:0% 80%;
    background-size:350% 150%;
    margin-bottom:1rem;
    animation:slideIn 1s ease-in-out;
}
.subFontStyle{
    font-size:600%;
    color:transparent;
    background-clip:text;
    -webkit-background-clip:text;
    -webkit-text-stroke:3px black;
    -webkit-text-fill-color: white;
    text-shadow: 1px 2px ;
    margin:1rem auto;
    animation:slideDown 2.5s ease-in-out;
}
.hidden{
    opacity:0;
}
.coverPageReview{
    margin:0;
    position:relative;
    display:flex;
    align-items:center;
    justify-content:space-around;
}
.coverPageReview h1,.coverPageReview p, .coverPageReview span{
    color:white;
}
@media screen and (max-width:900px){
    .coverPageReview{
        flex-direction:column;
        gap:10px;
    }
    .custCoverPage >div{
        padding:0.5rem;
        background:rgba(0,0,0,.2);
    }
    .fontStyleCoverPage{
        margin-top:3rem;
    font-size:800%;
    }
    .subFontStyle{
        font-size:450%;
        -webkit-text-stroke:1.5px black;
        -webkit-text-stroke:1px red;
    }
}
@media screen and (max-width:600px){
    .custCoverPage >div{
        
        background:rgba(0,0,0,.2);
    }
    .fontStyleCoverPage{
        font-size:400%;
        }
        .subFontStyle{
            font-size:250%;
        }
        
}
/* -----------------------------COVERPAGE END ------------------*/
/* -----------------------------MAIN ------------------*/
.customDesign1 p,.customDesign1 h1,.customDesign1 span{
    font-family:var(--font-family);
    color:black;
}
.coverPageReview h1,.coverPageReview p, .coverPageReview span{
    color:white;
}
.title{
    font-family:var(--font-family);
    text-align:center;
    margin-top:3rem;
    color:white;
}
.stackContainer{
    justify-content:center;
    align-items:center;
    margin:2rem auto;
    width:100%;
}

.ratingcard{
    margin:1rem 1rem;
    display:flex;
    width:100%;
    place-items:center;
    flex-direction:column;
    color:black;

}
.gridRating{
    
    display:flex;
    align-items:center;
    flex-direction:column;
    /* box-shadow:1px 1px 4px 1px white; */
}
.custReview .reviews{
    height:38vh;
    padding:auto 1rem;
    overflow-y:scroll;
    overflow-x:hidden;
    margin:auto;
    scroll-snap-type: y mandatory;
    scroll-snap-stop:always;
}

@media screen and (max-width:900px){
    .reviews{
        height:26vh;
        margin:2rem auto;
    }
}
/* -----------------------------MAIN ------------------*/

/* -----------------------------INTROPAGE ------------------*/
.imageContainerAnimate{
    opacity:0;
    animation:transScribeImage 20s linear;
    animation-delay:1s;
    animation-direction:forwards;
}
.imageContainerAnimateLast{
    opacity:1;
    animation:transScribeImage 20s linear;
    animation-delay:1s;
    animation-direction:forwards;
    background-size:100% 100%;
    background-position:50% 50%;
    
}
.imageContainerClean{
    opacity:0;
    animation:none;
    background-size:0% 0%;
    background:white;
    
}

.imageContainerTitleOn{
opacity:1;
position:absolute;
top:62%;
left:37%;
transition:opacity 1s ease-in;
}
.imageContainerTitleOff{
opacity:0;
transition:opacity 0.5s ease-in;
}

@keyframes transScribeImage {
    0% {background-position: 0% 0%;opacity:0;background-size:200% 200%}
    10% {background-position: 10% 10%;opacity:0.37;background-size:200% 200%}
    20% {background-position: 20% 20%;opacity:0.68;background-size:200% 200%}
    30% {background-position: 30% 30%;opacity:1;background-size:200% 200%}
    40% {background-position: 40% 40%;opacity:1;background-size:200% 200%}
    50% {background-position: 50% 50%;opacity:1;background-size:200% 200%}
    60% {background-position: 50% 60%;opacity:1;background-size:200% 200%}
    70% {background-position: 40% 70%;opacity:0.8;background-size:170% 170%}
    90% {background-position: 30% 60%;opacity:0.5;background-size:130% 130%}
    100% {background-position: 30% 50%;opacity:0.2;background-size:100% 100%}
}
@media screen and ( max-width:600px){

}
/* -----------------------------INTROPAGE ------------------*/
/* -----------------------------Rental ------------------*/
.rentalContGrid{
    margin:auto;
    justify-content:center;
    align-items:flex-start;
    margin:2rem auto;
    width:100%;
    
}
.rentalContGrid>div{
    margin:0 auto;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    flex-direction:column;
    
}
.rentalContGrid>div>div{
    margin:auto;
    background:transparent;
    display:flex;
    place-items:center;
    flex-direction:column;
}
/* -----------------------------RENTAL ------------------*/
/* -----------------------------CALENDAR ------------------*/
.calendarFlex{
    margin:auto;
    display:flex;
    justify-content:flex-start;
    flex-wrap:wrap;
    max-width:350px;
    background:rgba(161, 212, 210, 0.979);
    border-radius:5%;
}
.custCalendarCard{
    background:rgba(6, 111, 197, 0.932);
}
.form{
    display:flex;
    flex-direction:column;
    align-items:stretch;
    justify-content:center;
    padding:1rem;
    border-radius:10%;
    box-shadow:var(--box-shadow-normal);
}
/* -----------------------------CALENDAR END ------------------*/
