*{margin:0;padding:0;box-sizing:border-box}






.showHide{
    margin-left:auto;
    color:black;
    text-decoration:none;
    transition:all 2s ease-out;
    font-size:100%;
}
.show{
    margin-left:50px;
    color:blue;
    font-size:120%;
    text-decoration:underline;
    transition:all 2s ease-in;
}
.form{
    width:100%;
    padding:0.5rem;
    padding:0.5rem;
    border-radius:5%;
}
.yesRecieved{
    display:block;
    position:absolute;
    top:0%;
    width:80%;
    left:5%;
    font-size:30px;
    color:blue;
    animation: showMsg 7s ease-in-out;
}
.hidden{
    display:none;
}

@keyframes showMsg{
    from {opacity:0;}
    50% {opacity:1;}
    to{opacity:0;}
}
.QuestionFormCard{
    box-shadow:1px 2px 10px 20px grey;
}