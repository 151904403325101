*{margin:0;padding:0;box-sizing:border-box}



.home:hover:after{
    display:block;
    position:absolute;
    background: white;
    color:black;
    padding:1rem;
    top:150%;
    width:50%;
    font-family:"Roboto";
    content:"page turner for HOME";
}
.destination:hover:after{
    display:block;
    position:absolute;
    background: white;
    color:black;
    padding:1rem;
    top:150%;
    width:50%;
    font-family:"Roboto";
    content:"page turner for DESTINATION";
}
.technology:hover:after{
    display:block;
    position:absolute;
    background: white;
    color:black;
    padding:1rem;
    top:150%;
    width:50%;
    font-family:"Roboto";
    content:"page turner for TECHNOLOGY";
}
.customContainer h1,.customContainer p{
font-family:var(--font-family);
color:black;
}
.contactMsg{
    background:rgba(0, 0, 0,.3);
    color:white;
    box-shadow:1px 2px 10px lightblue, -1px -2px 10px lightblue;
    text-align:center;
    animation: slideUp 10s ease-in-out;
}
@keyframes slideUp {
    from {opacity:0;transform:translateY(100%);}
    50% {opacity:1;transform:translate(10%,50%)}
    to {opacity:1;transform:translate(10%,10%)}
}
