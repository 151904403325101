:root{
  --background-1:linear-gradient(217deg,
    rgba(26, 1, 1, 0.8), rgba(8, 0, 0, 0) 60.71%),            
    linear-gradient(127deg, rgba(1, 19, 1, 0.8), rgba(0,255,0,0) 70.71%),            
     linear-gradient(336deg, rgba(0,100,255,.8), rgba(0,0,255,0) 70.71%);
    --background-2:linear-gradient(217deg,
    rgba(12, 0, 0, 01), rgba(2, 45, 56, 0.9) );
    
    --background-4:            
     linear-gradient(336deg, rgba(0,100,255,.8), rgba(0,0,255,0.9) 70.71%);
    
}
.custNav{
  
}
.navMain {
  position: fixed;
  top: 2%;
  left: -2%;
  z-index: 20;
  width: auto;
  height: auto;
  min-height:0;
  background:"transparent";
  transition: all 1.5s ease-in-out;
}
.mainNavListOn{
  opacity:1;
  margin-top: 2rem;
}
.navList > div:hover{
  color:black;
  background:"white";

}

.mainNavListOn > div > a > h1 {
  color: white;
}
.icon {
  color: white;
  margin-right: 1rem;
}
.custNavGridList >div:last-child{
  justify-self:start;
  
}

.smallNav:active {
  background-color: black;
}
.smallNavOnClick {
  min-height: "10vh";
  position: absolute;
  left: 5%;
  top: 5%;
  z-index: 100;
  background-color: black;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  box-shadow: 1px 1px 10px 2px white;
}
.smallNav:hover {
  box-shadow: 1px 1px 10px 2px red;
}
.image {
  width: 100%;
}
.navAnchor {
  position: relative;
}
@media screen and (max-width: 900px) {
  .smallNav {
    position: fixed;
    width: 80px;
    max-height: 80px;
    top: 1%;
    left: 1%;
  }
  .navMainLarge {
    position: fixed;
    top: 2%;
    left: 3%;
    min-height: 20vh;
    background-color: rgba(0, 0, 0, 0.8);
    width: 90vw;
    margin-bottom: 1rem;

    transition: all 1.5s ease-in-out;
  }
}

@media screen and (max-width: 600px) {
  .smallNav {
    position: fixed;
    width: 30px;
    max-height: 30px;
    top: 1%;
    left: 1%;
  }
  .navMainLarge {
    position: fixed;
    top: 2%;
    left: -3%;
    z-index: 2000;
    min-height: 60vh;
    background-color: rgba(0, 0, 0, 0.8);
    width: 90vw;
    transition: all 1.5s ease-in-out;
  }
}
@keyframes showList {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* -----------COPYWRIGHT------------- */
.copywriteContainer{
  width:100vw;
    margin:0;padding:0;
    background: linear-gradient(0.25turn, #011f20, #a6b6a6, #160303);
}
.main {
  min-height: 5vh;
  margin: auto 5px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-top: 5px; */
  border-top:1px solid white;
  border-bottom:1px solid grey;
  color:white;
  
}
.main > div {
  margin: auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  color:white;
  column-gap:1rem;
  position:relative;
  overflow:hidden;
}
.main >div h1{
    color:white;
    font-family: var(--font-family);
}

.logo {
  max-height: 75px;
  max-width: 75px;
  position: relative;
  flex-wrap:nowrap;
  align-items:center;
  justify-content:center;
  margin-left:60px;
}
.logo > div {
    margin:1rem auto;
  width: 100%;
  height:100%;
  box-shadow:1px 1px 5px 1px white;
}
.privacyPolicy{
    justify-content:flex-start;
    align-items:center;
    width:350px;
    cursor:pointer;
}
.jwt{
    position:absolute;
    right:-140px;
    background: linear-gradient(#300303, #9198e5);
    transition:all 1s ease-in-out;
}
.jwt:hover{
    transform:translateX(-155px);
    transition:all 1s ease-in-out;
}
.jwt >img{
width:65px;
height:65px;
}

@media screen and (max-width:900px){
  .privacyPolicy{
    justify-content:end;
    align-items:center;
    width:350px;
    cursor:pointer;
}
    .jwt{
        position:absolute;
        top:50%;
        right:-18%;
        transition:all 1s ease-in-out;
    }
    .jwt >img{
        width:55px;
        height:55px;
        }
}
@media screen and (max-width:600px){
  .privacyPolicy{
    justify-content:center;
    align-items:center;
    width:350px;
    cursor:pointer;
}
    .main >div>div>h1>span{
        display:none;
    }
    .jwt{
        position:absolute;
        top:0%;
        right:-46%;
        transition:all 1s ease-in-out;
    }
    .jwt >img{
        width:55px;
        height:55px;
        }
}
