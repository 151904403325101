* {
    margin: 0;
    padding: 0;
box-sizing: border-box;
}
:root{
    --font-family:"'Philosopher', sans-serif";
    --font-family2: "'Philosopher', sans-serif";
    --background-image-1-left:linear-gradient(to left, violet, rgb(152, 7, 255), rgb(18, 243, 18), rgb(248, 236, 236), yellow, orange, red);
    --background-image-1-left_1:linear-gradient(to left,#2CF6B3, #A3CEF1, #D8F793, #D8F793, #EF233C, #0000FF);
    --background-image-1-turn:linear-gradient(0.25turn, violet, indigo, green, blue, yellow, orange, red);
    
    --background-image-2-left:linear-gradient(to left, #0000ff, rgb(188, 243, 116) 50%,rgb(255, 21, 5));
    --background-1:linear-gradient(to right, rgb(255, 21, 5), rgba(245, 241, 6, 0.562) 30% );
    --background-process:linear-gradient(to right, rgb(255, 21, 5), rgb(152, 7, 255),blue,white,pink );
    --background-33:            
    linear-gradient(136deg, rgba(240, 12, 12, 0.8), rgba(0,0, 0, 0.8) 20% );
    --background-33-light:            
    linear-gradient(136deg, rgba(240, 12, 12, 1), rgba(248, 241, 241, 0.8) 50% );
    --background-44:linear-gradient(217deg, #edeec0, #82A0BC 0.71%,black 50%);
    --background-555:            
     linear-gradient(136deg, hsl(349, 100%, 63%), hsl(212, 16%, 74%) 40.71%);
     --background-1111:linear-gradient(to right, rgba(102, 11, 4, 0.726), rgba(15, 10, 68, 0.986) 5% ,rgba(1, 0, 5, 0.993)30%,rgba(0, 0, 0, 1));
    --background-8:linear-gradient(to right, rgba(214, 5, 5, 1), rgb(43, 4, 19) 40.71%,rgba(34, 245, 6,.6)60.71%);
     --background-111:linear-gradient(to bottom, hsl(180, 7%, 2%), hsl(192, 7%, 3%) 90% ,hsl(198, 100%, 7%) 0%,hsl(10, 27%, 5%) 0%);
     --background-design:linear-gradient(217deg, rgba(250, 12, 12, 0.9), rgba(32, 6, 2, 0.926) 20.71%,rgba(17, 3, 1, 0.926),black 37%);
     --background-design-CoverPage:linear-gradient(to right, rgb(78, 4, 4), rgb(199, 170, 170)50% ,rgb(78, 4, 4)30%);
     --box-shadow-normal: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
     --word-break:break-all;
     --writing-mode-vertical:vertical-rl;
     --writing-mode-horizontal:horizontal-rl;
     /* writing-mode: var(--writing-mode-vertical); long word=> text-overflow: ellipsis;*/
     --background-design1:linear-gradient(217deg, #DE8F6E, #5CA4A9,#fdfffc 30.71%,hsl(224, 64%, 64%) 70%);
     --background-text-1:linear-gradient(to right, rgb(7, 32, 250), rgb(216, 223, 243)85%, rgb(253, 252, 252)15%);
     --background-hopefulHarvest:linear-gradient(to right, rgb(247, 6, 6), rgb(6, 150, 61)80% ,rgba(240, 244, 245, 0.9)90%);
     --background-hopefulHarvest-main:linear-gradient(217deg, rgba(5, 71, 8, 0.486), rgb(245, 248, 246)52.5% ,rgba(9, 63, 2, 0.664)95%);
     --background-hopefulHarvest-flowDisp:linear-gradient(217deg,rgb(41, 2, 2), rgb(117, 110, 5)50% ,rgb(8, 8, 8)60%);
     --background-hopefulHarvest-handCrafted:linear-gradient(217deg,rgb(253, 6, 6), rgb(242, 248, 245)50% ,rgb(195, 236, 10)90%);
     --background-hopefulHarvest-family:            
    linear-gradient(136deg, rgba(139, 138, 138, 0.8), rgb(233, 240, 241) 20% );
     --background-hopefulHarvest-msg:            
    linear-gradient(217deg, rgb(119, 119, 119), rgb(231, 238, 237) 15% );
     --background-hopefulHarvest-familyTypo:            
    linear-gradient(136deg, rgba(17, 17, 17, 0.8), rgb(39, 38, 38) 50% );
    --background-design3:linear-gradient(to right, rgba(185, 182, 9, 0.692),rgba(0, 0, 0, 0.9)30%);
    --background-videoList:linear-gradient(217deg,rgb(80, 55, 2), rgb(60, 61, 60)10%);
    --background-video-banner:linear-gradient(217deg,rgb(80, 55, 2), rgb(60, 61, 60)10%);
    --background-color-video:whitesmoke;
    --background-section-blog:linear-gradient(217deg, rgba(17, 1, 48, 0.8), rgb(247, 243, 243) 20% );
    --background-section-blog-sm:linear-gradient(217deg, rgba(17, 1, 48, 0.8), rgb(247, 243, 243) 5% );
    --background-FaqQuestion:            
    linear-gradient(217deg, rgb(6, 200, 248), white 15% );
    --background-wedding:            
    linear-gradient(136deg, rgba(6, 216, 253, 0.8), rgb(165, 240, 245) 50% );
    --background-designs-chat:linear-gradient(0.5turn, violet, indigo, green, #0000ff, yellow, orange, red);
    --background-resto:linear-gradient(0.5turn, rgb(8, 4, 250), rgb(93, 90, 243), rgb(10, 179, 247), rgb(7, 215, 252), yellow, rgb(248, 233, 16), red);
    --background-service:            
    linear-gradient(to left, rgb(7, 222, 250), rgb(248, 6, 6) 85% );
    --background-service1:            
    linear-gradient(to right, rgb(248, 6, 6), rgb(7, 222, 250) 55% );
    --background-icon-right:            
    linear-gradient(217deg, rgb(10, 0, 0), rgb(251, 252, 252) 25% );
    --background-icon-left:            
    linear-gradient(37deg, rgb(15, 0, 0), rgb(251, 252, 252) 25% );
    --background-scalable:linear-gradient(0.5turn, rgb(10, 10, 10), rgb(3, 51, 35),#040483,rgb(2, 48, 17),rgb(13, 13, 14));
    --background-scalableFont:linear-gradient(0.5turn, rgb(245, 12, 12), rgb(237, 243, 241),rgb(245, 13, 13));
    --background-scalableFont2:linear-gradient(0.5turn, rgb(237, 243, 241),rgb(245, 13, 13),rgb(237, 243, 241));
    --background-topBanner:linear-gradient(0.5turn,black, rgb(10, 10, 10), rgb(3, 29, 51),rgb(4, 4, 131),rgb(3, 29, 51),rgb(10, 10, 10),black);
    --background-topBannerTop:conic-gradient(black, rgb(10, 10, 10) 180deg, rgb(3, 29, 51),rgb(4, 4, 131),rgb(3, 29, 51),rgb(10, 10, 10),black);
    ---background-one:linear-gradient(to left,#191919,#BAFFDF,#F25757,#0000FF,#8499B1);
    --background-two:linear-gradient(to left,#00A5CF,#040483,#D6F8D6,#AF9AB2,#251605);
    --background-three:linear-gradient(to left,#D14081,#2D82B7,#FEFEE3,#050517,#1EA896);
    --background-four:linear-gradient(to left,#AEE5D8,#79ADDC,#FFEAEC,#717568,#011638);
    --background-design3:hsl(248, 100%, 98%);
  }
  .App {
    margin:0;padding:0;
    font-family:var(--font-family);
    
  }
  h1,h2,h3,h4,h5,h6,span,p{font-family:var( --font-family);color:"white"}
  
  .Appcenter{
    margin:0;
    margin-top:-5px;
    position:relative;
    justify-content:center;
    align-items:stretch;
    flex-direction:column;
    display:flex;
    width:100vw;
    
  }
  .Appcenter h1, .Appcenter span, .Appcenter p {
    font-family:var(--font-family);
  }

img.sixDegreeCalc {
    max-width:30%;
}

img.sixDegPic1{
    width:20%;
}
h1 .misc{
    margin:auto;
    -webkit-text-stroke: 1px black;
    background-clip:text;
    -webkit-background-clip:text;
    background-image:var(--background-44);
    background-size:200% 100%;
    color:transparent;

}
@media screen and (max-width:900px){
    img.sixDegPic1{
        width:50%;
    }
}
@media screen and (max-width:600px){
    img.sixDegPic1{
        width:100%;
    }
}