*{margin:0;padding:0;box-sizing:border-box}

.relaxTypo{
    text-align:center;
    font-weight:bold;
    
}
.question{
    background:transparent;

    
}
.answer{
    background:transparent;
    
}