
:root{
    --font-family:"'Philosopher', sans-serif"
  }
  
.serviceBoxEffect{
    width:100%;
    max-height:600px;
    transition:max-height 1s ease-in-out;
    
}
.serviceBoxEffect >h1,.result >h1{
    font-family: var(--font-family);
    font-weight:300;
}
.handleClickOff{
    opacity:0.9;
    font-weight:400;
    font-family: var(--font-family);
}
.handleClickOn{
    opacity:1;
    font-weight:400;
    font-family: var(--font-family);
    font-size:150%;
}
.fontSize{
    font-size:150%;
  }
.handleClick >h1{
    font-family: var(--font-family);
}
.handleClick:hover{
    opacity:1;
    font-weight:500;
}

.serviceLiEffect{
    width:90%;
    margin-right:10px;
    display:flex;
    flex-wrap:wrap;
    justify-content:flex-start;
    align-items:flex-start;
    gap:10px;
    animation:showDown 1s ease-in-out;
}
.result{
    animation:showDown 1s ease-in-out;
}

@keyframes showDown {
    from {transform:translateY(-50%);opacity:0;};
    to {transform:translateY(-0%);opacity:1;};
    
}
.list{
    list-style-type:"\1F44D";
    flex:130px;
    list-style-position: inside;
}

.list >span{
    margin-left:5px;
}
@media screen and (max-width:600px){
    .serviceBoxEffect{
        width:100%;
        max-height:900px;
        
    }
}