*{margin:0;padding:0;box-sizing:border-box}


.openModalOpen{
    position:absolute;
    top:50%;
    animation: moveCloseBtn 1s ease-in;
}
.openModalClose{
    position:absolute;
    top:auto;
    animation: moveOpenBtn 1s ease-in;
}
@keyframes moveOpenBtn {
    from {opacity:0;transform:translateY(-100%);}
    to { opacity:1;transform:translateY(40%);}
}
@keyframes moveCloseBtn {
    from {opacity:0;transform:translateY(40%);}
    to { opacity:1;transform:translateY(0%);}
}

.contactMsg{
    background:"transparent";
    color:white;
    box-shadow:1px 2px 10px lightblue, -1px -2px 10px lightblue;
    text-align:center;
    animation: slideUp 10s ease-in-out;
}
@keyframes slideUp {
    from {opacity:0;transform:translateY(100%);}
    50% {opacity:1;transform:translate(10%,50%)}
    to {opacity:1;transform:translate(0%,0%)}
}