
*{
  box-sizing: border-box;
}
body{
    position:relative;
    font-family:var(--font-family);
  }
  .mainMain{
    background:var(--background-111);
    width:100vw;
    margin:0;
    padding:0;
    z-index:50;
  }
  .fontStyle{
    margin:auto;
    align-self:center;
    font-family: var(--font-family);
    background-image: var(--background-image-1-left_1);
    background-size:150% 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom:0.62rem;
    font-size:300%;
    animation:moveAlways2 30s linear infinite reverse;
  }
  
  .fontStyle_2{
    font-family: var(--font-family);
    background-image: var(--background-555);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: transparent;
        margin-bottom:0.62rem;
       transition: all 1s ease-in;

  }
  
  .On{
    color:white;
    text-decoration:underline;
    cursor:pointer;
    font-family:var(--font-family);
  }
  .linkOn{
color:white;
  }
  .linkOn:hover{
    font-family: var(--font-family);
    background-image: var(--background-image-1-left);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: transparent;
        margin-bottom:0.62rem;
       transition: all 0.5s ease-in;
  }
  
  @keyframes moveAlways2 {
    0% {background-position:0% 50%;}
    50% {background-position:150% 50%;}
    100% {background-position:0% 50%;}
  }
  /* ------------------COVER--------------- */
  
  .fontStylelarge{
    margin:auto;
    font-family: var(--font-family);
    /* background-image: var(--background-image-1-left); */
    background-position:50% 50%;
    background-size:150% 150%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom:0.62rem;
    font-weight:bold;
    animation: moveAlways 60s infinite linear reverse;
    font-size:2400%;
  }
  .fontStyleMedium{
    margin:auto;
    font-family: var(--font-family);
    /* background-image: var(--background-image-1-left); */
    background-position:50% 50%;
    background-size:150% 150%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom:0.62rem;
    font-weight:bold;
    animation: rotateAlways 60s infinite linear reverse;
    font-size:2200%;
  }
  
  @keyframes moveAlways {
    0% {background-position:0% 0%;}
    50% {background-position:150% 150%;}
    100% {background-position:0% 0%;}
  }
  @keyframes rotateAlways {
    0% {background-position:0% 0%;}
    20% {background-position:60% 60%;}
    30% {background-position:90% 90%;}
    50% {background-position:150% 150%;}
    60% {background-position:120% 120%;}
    70% {background-position:50% 50%;}
    100% {background-position:0% 0%;}
  }
  /* ------------------COVEREND--------------- */
  /* -------WeDesign EFFECT----------- */
  .showWeDesign{
    opacity:1;
    transition:opacity 1s ease-in;
  }
  .hideWeDesign{
    opacity:0;
    transition:opacity 1s ease-in;
  }
  
  .mainInnerSection{
    margin:"5rem auto";
    width:100%;
    padding:10px;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:center;
    
  }
  .mainInnerSection > div,.mainInnerSection > div>h1{
    margin:auto;
    width:100%;
  }
  .ourwebDesignBgOn{
   
    align-self:flex-start;
    font-family: var(--font-family);
    color:black;
    /* background-color:rgba(255,255,255,.3); */
    transition:all 1.5s ease-in-out;
    padding:10px;
  }
  .ourwebDesignBg{
    margin:auto;
    align-self:center;
    font-family: var(--font-family);
    color:white;
    /* background-color:rgba(255,255,255,.3); */
    transition:all 1.5s ease-in-out;
    padding:10px;
  }
  
  .fontStyleDesignOn{
    margin:auto;
    align-self:center;
    font-family: var(--font-family);
    background-image: var(--background-image-1-left_1);
    background-size:150% 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom:0.62rem;
    font-size:400%;
    animation:moveAlways2 30s linear infinite reverse;
  }
  .fontStyleNormal{
    margin:auto;
    font-family: var(--font-family);
    background-image: var(--background-image-1-left_1);
    background-size:100% 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom:0.62rem;
    font-size:400%;
    
  }
  .fontStyleNormalSpan{
    margin:auto;
    font-family: var(--font-family);
    background-image: var(--background-image-1-left);
    background-size:150% 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom:0.62rem;
    
    
  }
  
  .designCompanyOn{
    margin:auto ;
    color:white;
    /* background:rgba(0,0,0,.3); */
    transition: all 1s ease-in-out;
  }
  
  
  @keyframes showUp {
    from {opacity:0;transform:translateY(-50%);}
    to {opacity:1;transform:translateY(0%);}
  }
  @keyframes showOut {
    from {opacity:1;transform:translateY(0%);}
    to {opacity:0;transform:translateY(-50%);}
  }
  @media screen and (max-width:920px){
    .fontStylelarge{
      font-size:1700%;
      padding:0.5rem auto;
      font-weight:bold;
      writing-mode:vertical-rl
    }
    .fontStyleMedium{
      font-size:1400%;
    }
   
  }
  @media screen and (max-width:900px){
    .fontStylelarge{
      font-size:1700%;
      padding:0.5rem auto;
      font-weight:bold;
      writing-mode:vertical-rl
    }
    .fontStyleMedium{
      font-size:1300%;
    }
   
  }
  @media screen and (max-width:800px){
    .fontStylelarge{
      font-size:1100%;
      padding:0.5rem auto;
      font-weight:bold;
      writing-mode:vertical-rl
    }
    .fontStyleMedium{
      font-size:1100%;
    }
  }
  @media screen and (max-width:600px){
    .fontStylelarge{
      font-size:900%;
      padding:0.5rem auto;

    }
    .fontStyleMedium{
      font-size:700%;
    }
    
    
  }
  @media screen and (max-width:560px){
    .fontStylelarge{
      font-size:800%;
      padding:0.5rem auto;

    }
    .fontStyleMedium{
      font-size:720%;
    }
    
    
  }
  @media screen and (max-width:420px){
    .fontStylelarge{
      font-size:760%;
      padding:0.5rem auto;

    }
    .fontStyleMedium{
      font-size:600%;
    }
    
    
  }
  @media screen and (max-width:400px){
    .fontStylelarge{
      font-size:800%;
      padding:0.5rem auto;

    }
    .fontStyleMedium{
      font-size:600%;
    }
    
    
  }
  @media screen and (max-width:380px){
    .fontStylelarge{
      font-size:700%;
      padding:0.5rem auto;

    }
    .fontStyleMedium{
      font-size:600%;
    }
    
    
  }
  
  @media screen and (max-width:900px){
    
    .fontStyleDesignOn{
      margin:auto;
        align-self:center;
        font-family: var(--font-family);
        background-image: var(--background-image-1-left);
        background-size:150% 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: transparent;
        margin-bottom:0.62rem;
        font-size:300%;
        animation:moveAlways2 30s linear infinite reverse;
      
    }
    .fontStyle{

    }
  }
  @media screen and (max-width:600px){
    
    .fontStyleDesignOn{
      margin:auto;
        align-self:center;
        font-family: var(--font-family);
        background-image: var(--background-image-1-left);
        background-size:150% 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: transparent;
        margin-bottom:0.62rem;
        font-size:200%;
        animation:moveAlways2 30s linear infinite reverse;
      
    }
    .fontStyle{

    }
  }
  
  /* -------SECTION EFFECT----------- */
  h1,.write{
    font-family:var(--font-family2);
    color:white;

  }
  .App_header {
    min-height: 100vh;
    width:100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin:0;
    background:var(--background-111);
    
  }
  .zebra{
    position:relative;
    /* height:180vh; */
    width:100%;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    
  }
  .bannerWhite{
    position:relative;
  min-height:15vh;
  display:flex;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    font-family: var(--font-family2);
  }
  .scrollGroupOn >div{
 border-bottom:1px solid pink;
 transition: all 1.5s ease-in;
  }
  .scrollGroupOOff >div{
 border-bottom:1px solid black;
 transition: all 1.5s ease-in;
  }
  .bannerWhite >div{
    display:flex;
    margin:auto;
    justify-content:space-between;
    align-items:center;
    width:600px;
    overflow:hidden;
    box-sizing:border-box;
    scroll-snap-type: x mandatory;
    
  }
  .bannerWhite >div>div>h1{
    font-family: var( --font-family2);
   
  }
  
  .bannerWhite >div>div{
    flex:0 0 600px;
    scroll-margin-left:0;
    scroll-snap-align: center;
    animation:scrollThis 12s infinite;
    text-align:center;
  }
  @keyframes scrollThis{
    /* from{transform:translateX("0%");} */
    to{transform:translateX(-600px);}
  }
  
  
  
  .contact{
    width:80%;
    margin:1rem auto;
  }
  
  @media screen and (max-width:900px){
  
  
  
  }
  @media screen and (max-width:600px){
    .bannerWhite >div>div{
      flex:0 0 350px;
      scroll-margin-left:0;
      scroll-snap-align: end;
      animation:scrollThis 12s linear infinite;
      text-align:center;
    }
  
  }
  /* ---------SECOND SECTION-------------- */
  .afterZebraWhite{
    min-height:50vh;
    background-size:200% 200%;
    background-position:50% 50%;
    width:100%;
    display:flex;
    justify-content:space-around;
    align-items:center;
    flex-direction:row;
    flex-wrap:nowrap;
    gap:10px;
  }
  .afterZebraWhite >div{
    flex:1;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    /* width:350px;  */
  }
  .afterZebraWhite >div>h1,h1,.write{
    color:white;
    font-family:var(--font-family2);
    font-weight:300;
  }
  .afterZebraWhite >div:nth-child(2n){
    background-color:rgba(0,0,0,.2);
    border-left:1px dashed white;
    border-right:1px dashed white;
  }
  .afterZebraWhite >div:nth-child(2n-1){
    background-color:rgba(0, 100, 8, 0.15);
  }
 /*-------------------- MAIN SERVICES---------------- */
 .mainService{
  /* background:var(--background-111); */
  width:100%;
  z-index:100;
  padding:5vh auto;
  padding:auto 2vh;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  overflow:hidden;
}
.fontServices{
  background-clip:text !important;
  -webkit-background-clip:text !important;
  color:transparent !important;
  -webkit-text-stroke:1px white !important;
  background:var(--background-8);
  font-size:200%;
}

  
  .afterZebraWhiteMain >div>div > h1{
    color:white;
    font-family:var(--font-family);
  }
  .afterZebraWhiteMain > div{
    position:absolute;
    background:rgba(0,0,0,0.7);
    top:10%;
    right:1%;
    display:flex;
    width:45%;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding-block:1rem;
    gap:5px;
  }
 
  @keyframes showMsgLion{
    from { opacity:.8;}
    to { opacity:1;}
  }
 

  
  @media screen and (max-width:900px){
    .afterZebraWhite{
      width:100%;
      min-height:80vh;
      background-size:120% 100%;
      justify-content:center;
      flex-direction:column;
      gap:10px;
    }
    .afterZebraWhite >div{
      flex:1;
      width:100%;
      height:100%;
      
    }
    
    .afterZebraWhiteMain > div{
      top:10%;
      right:0%;
      width:70%;
      display:flex;
      justify-content:center;
      align-items:center;
      gap:2px;
      padding:10px;
    }
  }
  @media screen and (max-width:600px){
    .afterZebraWhite{
      width:100%;
      /* height:100%; */
      justify-content:center;
      flex-direction:column;
      gap:10px;
      height:50vh;
    }
    .afterZebraWhite >div{
      flex:1;
      width:100%;
      height:100%;
      
    }
    
    .afterZebraWhiteMain > div{
      top:1%;
      left:0%;
      display:flex;
      width:100%;
      justify-content:center;
      align-items:center;
      background:transparent;
      gap:5px;
      
    }
  }
  .mainZebra{
    margin:5rem auto;
    background:var(--background-111);
  }
  /*-------------------- MAIN SERVICES END---------------- */
  .scrollerParent {
    text-align: left;
    width: 100%;
    height: 250px;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* flex-direction:column; */
    border: 1px solid white;
    scroll-snap-type: x mandatory;
    box-sizing:border-box;
    scrollbar-color:rebeccapurple green;
  }
  
  .scrollerParent > div {
    border:1px solid white;
    flex: 0 0 100%;
    width: 100%;
    height:250px;
    text-align:center;
    background-color: #663399;
    color: #fff;
    font-size: 30px;
    scroll-margin-left:0;
    scroll-snap-align: start;
    color:white;
  }
  
  .scrollerParent > div:nth-child(2n) {
    background-color: #fff;
    color: #663399;
  }
  
  /* ---------- HIRTY YEARS ---------------- */
  .thirtyYearsMain{
    background:var(--background-111);
    width:100vw;
    margin:0 auto;

  }
  /* ---------- THIRTY YEARS END ---------------- */
  /* ---------- SECTION 3 ---------------- */

  
  
  .custVideoBlog h1{
    font-family: var(--font-family);
    color:black;
  }
  .custVideoBlog >div>img{
    width:100%;
    
  }
  @media screen and (max-width:900px){
    .custVideoBlog >div{
      flex:350px;
    }
    
  }
  @media screen and (max-width:600px){
    .custVideoBlog >div{
      flex:350px;
    }
    
  }

 
  
  /*-------------- INNOVATORS --------- */
  
  .changeColor{
    background:white;
    transition:all 1s ease-in;
    cursor:pointer;
  }
  .removeColor{
    justify-content:flex-start;
    align-items:center;
    background:var(--background-44);
    transition:all 1s ease-in;
    cursor:pointer;
    height:auto;
  }
  .changeTextColor{
    justify-content:center;
    align-items:center;
    transition:all 1s ease-in;
    height:auto;
    font-family: var(--font-family);
    background-image: var(--background-1);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: transparent;
        /* margin-bottom:0.62rem; */
       transition: all 1s ease-in;
  }
  .removeTextColor{
    color:white;
    justify-content:center;
    align-items:center;
    transition:all 1s ease-in;
  }
  .rowSpacing{
    justify-content:space-between;
    align-items:center;
  }
  .openLoadedTrue{
    justify-content:space-between;
    align-items:center;
    background:white;
  }
  .openLoadedfalse{
    justify-content:space-between;
    align-items:center;
    background:black;
  }
  
  .mainTextOn{
    display:block;
    color:black;
    padding:10px;
    height:100%;
    transition: 1s ease-in;
  }
  .mainTextMore2{
    margin:2rem auto;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    color:black;
    padding:10px;
    height:100%;
    transition: 1s ease-in;
   
  }
  .maineTextOff{
    display:none;
  }
  @keyframes innovateShowOn{
    from {opacity:0}
    to {opacity:1}
  }
  @keyframes innovateShowOff{
    from {opacity:0}
    to {opacity:1}
  }
  /*-------------- INNOVATORS END --------- */

  /*-------------- HELPING BRANDS --------- */

.mainHelping > div{
  margin:auto;
}
.mainGrid{
  margin:auto;
 
  
}
.mainGrid > div{
  margin:auto;
  padding:2rem;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
}
.textStatement{
    font-family: var(--font-family);
    /* background-image: var(--background-8); */
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: transparent;
        /* margin-bottom:0.62rem; */
       transition: all 1s ease-in;
       text-align:center;
       background-size:200% 150%;
       animation:moveAlways2 30s linear infinite reverse;
   
}
.textDesc{
  font-family: var(--font-family);
  text-align:start;

}
.helpingChildGrid{
  margin:auto;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}
.mainHelping .modal{
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:flex-start;
  margin:0.5rem auto;
  column-gap:3.5rem;
}
.mainHelping .modal .innerModal{
  width:50%;
}
@media screen and (max-width:900px){
  
  .mainHelping .modal{
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;
    width:100%;
    padding:1rem;
    column-gap:0.5rem;
  }
  .mainHelping .modal .innerModal{
    width:90%;
  }
}
@media screen and (max-width:600px){
  .mainHelping .modal{
  margin:auto;
  width:100vw;
  padding:1rem;
  column-gap:0;
  row-gap:0.5rem;
  border:1px solid red;
}
.mainHelping .modal .innerModal{
  width:100%;
}
}

  /*-------------- HELPING BRANDS END --------- */
   /* ---------- SCROLLER START---------------- */
   .mainScroller{
    width:100%;
    /* background:var(--background-111); */
    z-index:100;
    margin:0 auto;
    overflow:hidden;
  }
  .section4Container{
    display:flex;
    margin:auto;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    
  }
  .fontStyleScoller{
    margin:auto;
    align-self:center;
    font-family: var(--font-family);
    background-image: var(--background-image-1-left);
    background-size:150% 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom:2rem;
    margin-bottom:3vh;
    animation:moveAlways2 30s linear infinite reverse;
  }
  .scrollerParentSec4{
    text-align: left;
    width: 100%;
    margin:auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap:nowrap;
    box-sizing: border-box;
    position:relative;
    
  }
 
  
  .scrollerParentSec4 >div> div >h1{
    font-family:var(--font-family2);
    font-weight:300;
    color:black;
    
  }
  .scrollerParentSec4 >div> div >span{
    margin-right:20px;
    font-size:80%;
    color:blue;
    font-weight:100;
    
  }
  @keyframes scrollMsg {
    from {transform:translateX(0%);}
    to {transform:translateX(-2300%);}
  }

  /*-------------- SCROLLER END END 5 --------- */
  /*-------------- WHAT WE DO START --------- */
  .mainWhatWeDo{
    margin:5vh auto;
    min-height:50vh;
    background:transparent;
    width:100vw;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
  .custWhatWeDo >div{
    margin:auto;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;

  }
  .fontStyle2{
    color:transparent;
    background-image:var(--background-image-1-left_1);
    background-clip:text;
    -webkit-background-clip: text;
    background-size:100% 100%;
  }
  

  /*-------------- WHAT WE DO END  --------- */
 
  .section5Title{
    font-family: var(--font-family2);
    color:white;
    margin:2rem auto;
   text-align:center;
  }
  .stackParent{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    flex-wrap:nowrap;
    background:var(--background-111);
    width:100%;
    box-sizing: border-box;
    overflow-x:hidden;
    background-color:rgb(46, 28, 0);
    
  }
  
.card{
  margin:auto;
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  color:white;
  background-color:transparent;
  box-shadow:1px 1px 10px 1px white;
    
}
.card >h1{
  font-family: var(--font-family);
  color:white;
}
  .cardMedia{
    transform:scale(1);
    padding:auto ;
    width:100%;
    
    box-shadow:1px 1px 15px 1px white;
    
  }
  @media screen and (max-width:900px){
    .cardMedia{
      width:100%;
      box-shadow:1px 1px 15px 1px white;
      
    }
   
  }
  /* --------------COPYWRIGHT-------------- */
  