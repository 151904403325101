*{margin:0; padding:0; box-sizing: border-box;}
@property --angle-color {
    syntax:"<angle>";
    inherits:false;
    initial-value:0deg;
}
:root{
    --clr-1:rgb(3, 87, 253);
    --clr-2:rgb(36, 149, 255);
    --clr-3:rgb(119, 199, 252);
    --clr-4:rgb(98, 194, 250);
    --clr-5:rgb(36, 149, 255);
    --clr-6:hsl(220, 98%, 60%);
}


/*----------- top level--------------- */
/*----------- top Banner start--------------- */
h1,h2,h3,p {
    font-family:var(--font-family);
}
.titleTopBanner{
    font-family: var(--font-family);
    text-align:center;
    font-size:1000%;
    margin:1rem auto;
    -webkit-background-clip:text;
    -moz-background-clip: text;
    background-size:100% 100%;
    background-clip:text;
    color:transparent;
    -webkit-text-stroke:1px white;
    animation:topBannerTitleOn 40s linear infinite;
}
 .custTopBanner::after,.custTopBanner::before{
    content:"";
    position:absolute !important;
    inset:-0.4rem !important;
    z-index:-2 !important;
    border-radius:inherit;
   filter:blur(17px);
    background:conic-gradient(
        from  var(--angle-color),
        var(--clr-1),
        var(--clr-2),
        var(--clr-3),
        var(--clr-2),
        var(--clr-1),
        var(--clr-4)
    ) !important;
animation: rotateThis 25s linear infinite;
}
@keyframes rotateThis {
    0% {--angle-color:0deg;}
    100% {--angle-color:360deg;}
}
.subTitleBanner{
    font-family: var(--font-family);
    margin:1rem auto;
    -webkit-background-clip:text;
    background-image:var(--background-scalableFont2);
    -moz-background-clip: text;
    background-size:50% 150%;
    background-position:50% 100%;
    background-clip:text;
    color:transparent;
    -webkit-text-stroke:1px white;
    animation: growInOut 10s ease-in-out infinite;
}
.topBannerChildGrid >div{
 margin:auto 1rem;
 display:flex;
 flex-direction:column;
 width:100%;
 justify-content:center;
 align-items:flex-start !important;
 padding-inline:2rem;
}
.hr_line_top{
    height:1.5px;
    width:62%;
    background:var(--background-text-1);
    margin:3rem auto;
}
@keyframes growInOut {
    from {transform: scale(1) translate(1%,1%);}
    50% {transform:scale(1.03) translate(0%,0%);}
    to {transform:scale(1) translate(1%,1%);}
}
@keyframes topBannerTitleOn {
    from {background-size:15% 15%;}
    50% {background-size:100% 100%;}
    to {background-size:15% 15%;}
}
@media screen and (max-width:900px){
    .titleTopBanner{
        font-size:700%;
    }
    .topBannerChildGrid >div{
        padding-inline:0;
        margin:auto;
        width:100%;
    }
    .custTopBanner::after,.custTopBanner::before{
        inset:-0.3rem !important;
    }
}
@media screen and (max-width:600px){
    .titleTopBanner{
        font-size:450%;
    }
}
/*----------- top banner end--------------- */

/* GENERAL */

.alignColunm{
    margin:auto;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin-bottom:1rem;
    
    

}
.ourSpeciality>h2{
    display:flex;
    flex-direction:column;
justify-self:flex-start;
align-items:flex-start;
color:white;

}

.backgroundMain >div{
    margin:auto;
}

.title{
    font-family: var(--font-family);
    font-size:1000%;
    background-image:var( --background-text-1);
    -webkit-background-clip:text;
    -moz-background-clip: text;
    background-size:100% 100%;
    background-clip:text;
    color:transparent;
    animation:titleOn 1s ease-in-out;
}
@keyframes titleOn {
    from {opacity:0;}
    to {opacity:1;}
}
.alignRow{
    margin:2rem auto;
    justify-content:center;
    align-items:center;
    flex-direction:row;
    flex-wrap:nowrap

}
.hr_line{
    height:1.5px;
    width:62%;
    background:var(--background-text-1);
    margin:5rem auto;
}
.childGridSeal::after{
    opacity:0;
text-align:center;
place-items:center;
position:absolute;
content:"Thanks for comming, Merci pour la visite";
min-width:180px;
min-height:60px;
padding:1rem;
top:20%;
left:40%;
background:rgba(0,0,0,0.5);
border-radius:0%;
color:white;
font-size:110%;
animation: rotateIn 10s ease-in-out;
}
.hide{
    opacity:0;
}
@keyframes rotateIn {
    from {opacity:0; transform:translateY(-300%) skew(45deg);}
    50% {opacity:1; transform:translateY(0%) skew(0deg);}
    to {opacity:0; transform:translateY(-100%) skew(45deg);}
}
@media screen and (max-width:900px){
    .title{
        font-size:550%;
    }
    .hr_line{
        margin:2rem auto;
    }
}
@media screen and (max-width:600px){
    .title{
        font-size:450%;
       
    }
}

/*----------- top level END--------------- */
/*----------- STARPROJCONTACT--------------- */

.startProjTitle{
    font-family:var(--font-family-1);
    -webkit-background-clip:text;
    background-clip:text;
    color:transparent;
    -webkit-text-stroke: 1px white;
    font-size:100px;
    margin:auto 1rem;
    animation: swirlText 20s linear infinite;

}

.custStartInner{
    margin:1rem auto;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

}
.custStartInner button{
    margin-top:1rem;
}
@keyframes swirlText {
    from {background-position:0% 0%}
    50% {background-position:100% 100%}
    100% {background-position:0% 0%}
}
@media screen and (max-width:900px){
    
}
@media screen and (max-width:600px){
    .startProjTitle{
        font-size:250%;
       
    }
}
/*----------- STARPROJCONTACT END--------------- */
/*----------- SERVICEITEM END--------------- */
.servIntro{
    display:block;
    animation:scaleIn 1s ease-in;
}
@keyframes scaleIn {
    from {transform:scaleY(0);}
    to {transform:scaleY(1);}
}
.nameFontStyleOn{
background-clip:text !important;
-webkit-background-clip:text !important;
-webkit-text-stroke:1px white !important;
background-image:var(--background-service);
background-size:100% 100%;
background-position:50% 50%;
color:transparent;
transition:background-size 1.5s ease-in;

}
.nameFontStyleOff{
background-clip:text;
-webkit-background-clip:text;
-webkit-text-stroke:1px white;
background-image:var(--background-service);
background-size:200% 200%;
background-position:50% 50%;
color:transparent;
transition:background-size 1.5s ease-in;

}
.showSentence{
display:block;
opacity:1 !important;
height:auto;
transition:all 1s ease-in-out;
}
.hideSentence{
display:none;

}

/*----------- SERVICEITEM END--------------- */


/*----------- FAQS START--------------- */
.openFAQSOpen{
   margin: 1rem auto;
   width:100%;
   display:flex;
   justify-content:center;
   align-items:center;
   flex-direction:column;
   transform:scaleY(1);
   transition:all 1s ease-in;
}
.openFAQSClose{
   margin: 1rem auto;
   width:100%;
   display:flex;
   justify-content:center;
   align-items:center;
   flex-direction:column;
   transform:scaleY(0);
   transition:all 1s ease-in;
}
/*----------- FAQS END--------------- */

.QAGrid{
    margin: auto;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:center;
    gap:10px;
    width:100%;
    overflow-y:hidden;
}
.QAGridPic{
    margin: auto 0;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:center;
    gap:5px;
    width:100%;
    position:relative;
    
}
.QAgrid >div{
    margin: auto;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    scroll-margin-block: center;
    overflow-y:scroll;
    /* border:1px solid red; */
    height:46vh;
    padding:0.5rem;

}

.scrollChild{
    margin:auto;
    /* scroll-margin-block: center; */
}
@media screen and (max-width:900px){
    .QAgrid >div{
        
        height:55vh;
       
    
    }
}
@media screen and (max-width:600px){
    .QAgrid >div{
        
        height:80vh;
       
    
    }
}
/*----------- FAQS END--------------- */