*{margin:0;padding:0;box-sizing:border-box}

.noMessage{
    display:none;
   height:0px;
}
.showMessage{
    display:block;
    margin: 2rem auto;
    /* margin-top:2rem; */
    box-shadow:1px 2px 3px 2px lightyellow,-1px -2px 3px 2px lightyellow;
    padding:0.5rem;
    max-height:100%;
    font-size:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    background:rgba(255,255,255,.6);
    animation:showtest 2s ease-in-out;
    height:30vh;
    transition:height 2s ease-in-out;
}
@keyframes showtest {
    from {height:0px;
    font-size:0%;width:100%;}
    to{font-size:100%;height:100%;}
}
@media screen and (maxWidth:600px){
    .showMessage{
        height:40vh;
    }
    @keyframes showtest {
        from {height:0px;
        font-size:0%;width:100%;}
        to{height:100%;}
    }
}
@media screen and (maxWidth:860px){
    .showMessage{
        height:60vh;
    }
    @keyframes showtest {
        from {height:0px;
        font-size:0%;width:100%;}
        to{font-size:100%;height:100%;}
    }
}
.summaryPrice{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    background:rgba(255,255,255,.6);
    box-shadow:1px 2px 3px 3px white,-1px -2px 3px 3px white;

}
.summaryPaymentNoShow{
    display:none;
}
.summaryPaymentGrid{
    animation: showtest 2s ease-in;
}
.revealMessage{
    display:block;
    height:200px;
    padding:0.5rem;
    color:blue;
    animation: showMessage1 5s ease-in;
    background: rgba(255,255,0,.4);
    box-shadow:1px 2px 3px 10px white;

}
.noMessage{
    display:block;
    box-shadow:1px 2px 3px 10px black;
    font-size:40px;
    background:black;
    color:white;
}




@keyframes showMessage1 {
    from {opacity:0;transform:scale(0) ;height:0px;}
    50% {opacity:1;transform:scale(1.2) ;}
    to {opacity:1;height:110px;transform:scale(1);}
}