*{margin:0;padding:0;box-sizing:border-box}


.contactMsg{
    background:"transparent";
    color:white;
    box-shadow:1px 2px 10px lightblue, -1px -2px 10px lightblue;
    text-align:center;
    animation: slideUp 10s ease-in-out;
}
@keyframes slideUp {
    from {opacity:0;transform:translateY(100%);}
    50% {opacity:1;transform:translate(10%,50%)}
    to {opacity:1;transform:translate(0%,0%)}
}
.validEmail,.validUsername,.validPassword{
    color:green;
}
.not{
    color:red;
}