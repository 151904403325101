*{
    box-sizing:border-box;
    padding:0;
    margin:0;
}
:root{
    --font-family:"'Philosopher', sans-serif";
    --font-family2: "'Philosopher', sans-serif";
    --background-image-1-left:linear-gradient(to left, violet, indigo, green, blue, yellow, orange, red);
    --background-image-1-turn:linear-gradient(0.25turn, violet, indigo, green, blue, yellow, orange, red);
    --background-image-1-right:linear-gradient(to right, violet, indigo, green, blue, yellow, orange, red);
    --background-image-2-right:linear-gradient(to right, blue, rgb(188, 243, 116) 50%,rgb(236, 28, 13));
    --background-image-2-turn:linear-gradient(0.25turn, blue, rgb(188, 243, 116) 50%,rgb(236, 28, 13));
    --background-image-2-left:linear-gradient(to left, blue, rgb(188, 243, 116) 50%,rgb(236, 28, 13));
    --background-1:linear-gradient(217deg,
    rgba(26, 1, 1, 0.8), rgba(8, 0, 0, 0) 60.71%),            
    linear-gradient(127deg, rgba(1, 19, 1, 0.8), rgba(0,255,0,0) 70.71%),            
     linear-gradient(336deg, rgba(0,100,255,.8), rgba(0,0,255,0) 70.71%);
    --background-2:linear-gradient(217deg,
    rgba(12, 0, 0, 01), rgba(2, 45, 56, 0.9) );
    --background-3:            
    linear-gradient(127deg, rgba(1, 19, 1, 0.8), rgba(0,255,0,0.9) 70.71%);
    --background-4:            
     linear-gradient(336deg, rgba(0,100,255,.8), rgba(0,0,255,0.9) 70.71%);
    --background-5:            
     linear-gradient(336deg, rgba(22, 202, 46, 0.3), rgba(255, 0, 106, 0.2) 70.71%);
  }
.mainContainer{
    margin:0;
    padding:0;
    width:100vw;
    font-family: var(--font-family);
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    min-height:100vh;
    background: var(--background-2);
}
/*----- ABOUTMAIN AND ABOUTMAINAFTER START ------- */
.main{
    justify-content:center;
    align-items:center;
    margin:auto 0.5rem;
    font-family: var(--font-family);
    
    
}
.weDo{
    justify-content:center;
    align-items:center;
    margin:auto ;
    font-family: var(--font-family);
    
    
}
.main > div,.weDo > div{
    margin:auto;
    width:100%;
    /* box-shadow:1px 1px 5px 1px black; */
    padding:15px;
    border-left:1px solid rgba(0,0,0,.2);
}


.weDo >div>h1,.main > div >h1{
    font-family: var(--font-family);
    

}
.main >div>div>h1,.main >div>h2,.weDo >div >div >h1,.weDo >div >h2{
    font-family: var(--font-family);
    color:white;
}
.hr_underline{
    background: var(--background-1);
    width:100%;
    height:4px;
    margin:auto;
}
.turnOn{
    opacity:1;
    transform:translateX(0%);
    transition:all 1.5s ease-in-out;
}
.fontEffect{
    font-size:800%;
    background-image: var(--background-image-1-left);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
}
.turnOff{
    opacity:0;
    transform:translateY(12%);
    transition:all 1.5s ease-in-out;
}
.aboutMainAfterTurnOn1{
    opacity:1;
    transform:translateX(0%);
    animation:moveUp 1s ease-in-out;
}
@keyframes moveUp{
    from {opacity:0; transform:translateY(30%);}
    to {opacity:1; transform:translateY(0%);}
}
@media screen and (max-width:900px){
    .main,.weDo{
        margin:auto 0.5rem;
    }
    .fontEffect{
        font-size:400%;
    }
}
@media screen and (max-width:600px){
.main, .weDo{
    margin:auto 0;
}
.fontEffect{
    font-size:300%;
}
}
/*----- ABOUTMAIN AND ABOUTMAINAFTER END ------- */
/*----- ABOUTIMAGE  START ---------------------- */
.subContainer{
    margin:auto;
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    /* border:1px solid black; */
}
.WeWantToGrow{
position:absolute;
margin:auto;
height:15vh;
width:100%;
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
padding:1rem;
animation:slideIn 2.5s ease-in-out;
background-color:rgba(0,0,0,0.3);
}
.weWantToGrow >h1,.weWantToGrow >h3{
    background-color:rgba(0,0,0,0.1);
    color:white;
    padding:1rem;
}

@keyframes disappear {
    from {opacity:1;}
    to {opacity:0;}
}
.textSlideIn{
    margin:auto;
    text-align:center;
    animation: slideIn 1s ease-in-out;
    
}
.textSlideIn h1 {
    font-family: var(--font-family);
    
}
.secondSlideIn{
    animation: slideIn 2s ease-in-out;
    padding:1rem;
    background-color:rgba(0,0,0,.3);
}
@keyframes slideIn {
    from {transform:translateX(-100%);}
    to {transform:translateX(0%);}
}

/*----- ABOUTIMAGE  END ---------------------- */
/*----- LONGEVITY  START ---------------------- */
.mainLongevity{
    margin:auto;
    margin-top:10vh;
    display:flex;
    align-items:flex-start;
    justify-content:center;
    flex-direction:column;
}
.fontEffectTitle{
    
    background-image: var(--background-image-1-left);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
}

.hrLine{
    margin:3rem auto;
    width:100%;
    height:2px;
    background: var(--background-3);
}
.longevity{
    opacity:0;
    justify-content:flex-start;
    align-items:flex-start;
    transition: opacity 1s ease-in-out;
}
.longevityShow{
    opacity:1;
    justify-content:flex-start;
    align-items:flex-start;
    animation : showItem 1s ease-in-out;
}
@keyframes showItem {
    from {opacity:0;transform:translateY(30%);}
    to {opacity:1;transform:translateY(0%);}
}
.longevity h2,.logevity h6{
    font-family: var(--font-family2);
}
.intersectingElevate{
    padding: auto 5px;
   transition: all 1.5s ease-in-out;
}
.intersectingElevateRemove{
   box-shadow:none;
   transition: all 1.5s ease-in-out;
}

.longevityPicHover{
    opacity:0;
    width:250px;
    min-height:250px;
    position:absolute;
    top:0%;
    left:22%;
    z-index:2000;
    max-height:50px;
    animation:reveal 5s ease-in;

}
@keyframes reveal {
    from {opacity:1;transform:scale(0.5) ;}
    20% {opacity:1;transform:scale(1) ;}
    to {opacity:0;transform:scale(0.5) translateX(-300%);}
}
@media screen and (max-width:900px){
    .longevityPicHover{
        top:10%;
        left:62%;
    }
}

/*----- LONGEVITY  END ---------------------- */
/*----- FOLDER  START ---------------------- */

.folder{
    
}


.tabPosition{
    position:relative;
    cursor:pointer;
    min-height:80vh;
    width:15%;
    height:100%;
    box-shadow:1px -1px 5px 1px white ;
    background-color:black;
    transform:translateX(0%);
    transition:transform 1.5s ease-in-out;
}
.tab{
    
    height:100%;
    width:10%;
    display:flex;
    justify-content:center;

   
    /* min-height:90vh; */
}






.textArea >h1{
    font-family: var(--font-family);
    background-image: var(--background-hopefulHarvest-handCrafted);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: transparent;
        background-size:25% 100%;
        
}
.textArea >h2{
    padding:1rem;
    margin:1rem auto;

}
@media screen and (max-width:900px){
    
    .custFolderMain >div{
        scroll-snap-align:center !important;
    }
    .tab{
        max-height:5vh;
        position:relative;
        display:flex;
        place-items: center;
        width:100%;
    }
    
    .tab >h1{
       left:0%;
        transform:rotate(0deg);
    }
}
@media screen and (max-width:600px){
    .mainFolder{
        min-height:100vh;
        width:100%;
        padding:auto 20px;
    }
    .folder{
        width:100%;
        height:70vh;
        /* left:-100%; */
        /* border:1px solid red; */
    }
    .tab{
        max-height:5vh;
        position:relative;
        display:flex;
        place-items: center;
        width:100%;
    }
    
    .tab >h1{
       left:0%;
        transform:rotate(0deg);
    }
}


/*----- FOLDER  END ---------------------- */

/*----- DiveIn  START ---------------------- */

.mainDiveIn{
margin:5rem auto;
margin-bottom:15vh;
place-items:center;
/* border:1px solid black; */
width:100%;
}
.diveInGridItemMain{
    margin:auto;
    background-size:100% 100%;
    background-position:50% 50%;
    /* border:1px solid purple; */
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

}
.diveInGridItemMain>div>h1{
    font-size:190px;
    font-family: var(--font-family);
    background-image: var(--background-image-2-turn);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: transparent;
}
.diveInGridItem{
    margin:auto;
    display:flex;
    place-items:center;
    align-items:center;
    flex-wrap:nowrap;
    max-height:60vh;
    overflow:hidden;
    width:100%;
    /* border:1px solid black; */
}
.diveInGridItem>div{
    margin:auto;
    display:flex;
    flex-wrap:nowrap;
    position:relative;
    flex:0 0 50%;
    height:50vh;
    /* border:1px solid white; */
}



@media screen and (max-width:600px){
    .diveInGridItemMain>div>h1{
        font-size:100px;
    }
}
@media screen and (max-width:900px){
    
.diveInGridItem>div{
    flex:0 0 50%;
}
}
/*----- DiveIn  END ---------------------- */

/*----- SCROLLER START ---------------------- */
.mainScroller{
    min-height:40vh;
    width:100vw;
    position:relative;
    display:flex;
    overflow:hidden;
    justify-content:center;
    flex-direction:column;
    place-items:center;
    
}

.scrollerParent{
    margin:auto;
    width:100vw;
    overflow:hidden;
    scroll-snap-align:center;

}
.scroller{
 display:flex;
 flex-wrap:nowrap;
 align-items:center;
 justify-content:flex-start;
 overflow-x: hidden;
 scroll-snap-type: x mandatory;
    box-sizing:border-box;
}
.scroller2{
 display:flex;
 flex-wrap:nowrap;
 align-items:center;
 justify-content:flex-start;
 overflow-x: hidden;
 scroll-snap-type: x mandatory;
    box-sizing:border-box;
}
.scroller2>p{
    font-size:196px;
    cursor:pointer;
    -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: rgba(255,255,255,0.4);
  scroll-margin-left:0;
    /* scroll-snap-align: start; */
    animation:scrollScroller 20s ease-in-out reverse infinite;
}

.mainScroller:hover .scroller>p,
.mainScroller:hover .scroller2>p{
    font-size:196px;
    -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: rgba(255,255,255,0.4);
  scroll-margin-left:0;
    animation:scrollScroller 20s ease-in-out;
}
.scroller>p{
    font-size:196px;
    -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: rgba(255,255,255,0.4);
  scroll-margin-left:0;
    animation:scrollScroller 20s ease-in-out infinite;
}
@keyframes scrollScroller {
    from{transform:translateX(-100%);}
    to{transform:translateX(0%);}
}
.startProject{
position:absolute;
min-height:10vh;
background-color:rgba(255,255,255,0.1);
padding: 2rem 4rem;
top:32%;
border-radius:20% 5% 20%;
z-index:200;
justify-content:center;
align-items:center;
cursor:pointer;
}
.startProject>div{
    margin:auto;
    padding:10px;
    box-shadow:-1px 1px 5px 1px white;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-wrap:nowrap;
    max-width:450px;
    position:relative;
    place-items:center;
    background-color:rgba(0,0,0,.2);
}
.startProject > div svg{
    color:red;
    width:80%;
    font-size:64px;
    max-width:150px;
    transition:all 0.5s ease-in;
    
}
.startProject > div >Button{
    width:100px;
    position:relative;
}
.startProject > div:hover svg{
    position:absolute;
    width:100px;
    height:90px;
    transform:translateX(10%);
    transition: all 0.5s ease-in;
    
}

@media screen and (max-width:900px){
    
    .scroller{
        margin-bottom:1rem;
    }
    
    
}
@media screen and (max-width:600px){
    .startProject >div{
        width:350px;
    }
    .scroller, .scroller2{
        margin-bottom:1rem;
    }
    .scroller2{
        margin-top:2rem;
    }
    .scroller>p{
        font-size:186px;
        animation:scrollScroller 15s ease-in-out infinite;
    }
    .scroller2>p{
        font-size:186px;
        animation:scrollScroller 15s ease-in-out reverse infinite;
    }
}
/*----- SCROLLER END ---------------------- */
/*----- INFO START ---------------------- */
.contactInfo{
    display:flex;
    flex-direction:column;
    place-items:center;
    margin-bottom:2rem;
    position:relative;
    place-items:center;
}
.infoFontStyle{
    font-family: var(--font-family);
    background-image: var(--background-image-1-left);
    background-size:400% 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
}
.link:hover:before{
    display:block;
    position:absolute;
    top:56%;
    left:58%;
    width:200px;
    height:200px;
    font-size:30px;
    font-family: var(--font-family);
    background-image: var(--background-image-1-left);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    content:attr(data-content);
    animation:slideInDown 1s ease-in-out;
}
@keyframes slideInDown{
    from {opacity:0;transform:translate(-10%,-100%);}
    to {opacity:1;transform:translate(0%,0%);}
}

/*----- INFO END ---------------------- */
.media{
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-wrap:wrap;
    width:350px;
    padding:0.5rem;
    background:rgba(255,255,255,0.2);
}
.flexContact{
    margin:auto;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-wrap:wrap;
    gap:20px;
}
.flexContact >div{
    margin:auto;
    
}
.flexColumn{
    margin:auto;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-direction:column;
}
/*---------SCROLLER---------------*/
/*---------SOCIAL MEDIA---------------*/
.iconLogo{
    
    width:60%;
}

@media screen and (max-width:900px){
    .icon{
        width:50%;
    }
    .iconLogo{
    
        width:70%;
    }
}
/*---------SOCIAL MEDIA---------------*/




