


.twoColumn{
    column-count:2;
    column-gap:2em;
}
@media screen and (max-width:600px){
    .twoColumn{
        column-count:1;
        column-gap:2em;
    }
}