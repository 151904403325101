*{margin:0;padding:0;box-sizing:border-box}

.showSummary{
    position:absolute;
    margin:auto;
    top:20%;
    width:100%;
    padding:1rem;
    text-align:center;
    background:rgba(255, 255, 255,.7);
    animation:growIn 1s ease-in-out;

}
.hideSummary{
    display:none;
}
.clickSummary{
    display:block;
    position:absolute;
    color:blue;
    right:2%;
    top:100%;
    cursor:pointer;
    
    
}

.showDescription{
   padding:1rem;
   position:absolute;
   top:50%;
   cursor:pointer;
   animation:growIn 1s ease-in-out;

}
@keyframes growIn {
    from {opacity:0;transform:translateX(-100%);}
    to {opacity:1;transform:translateX(0%);}
}
@media screen and (max-width:900px){
    .showDescription{
        top:25%;
    }
}
@media screen and (max-width:600px){
    .showDescription{
        top:35%;
    }
}
/*------------------ PROJECT.JS----------------*/
.projectCard h1,.projectCard p{
color:black;

}
/*------------------ PROJECT.JS----------------*/
