*{margin:0;padding:0;box-sizing:border-box}

.mainBanner3Container h1,.mainBanner3Container p, .mainBanner3Container span{
color:black;
}
.showStack{
display:block;
animation: growAbout 1s ease-in;
margin-top:1rem;
opacity:1;
background:rgba(0,0,0,.3);
padding:1rem;
}
@keyframes growAbout{
    from { opacity: 0;margin-top:0;background:rgba(255, 255, 255, 0.1) ;}
    to { opacity:1;margin-top:1rem;background:rgba(255, 255, 255,0.3) ;}
}
.hide{
    display:none;
    padding:1rem;
    background:rgba(255, 255, 255,.3);

}
.showPhrase{
    animation:transformX 1.5s ease-in-out;
    position:absolute;
    margin:auto;
    top:10%;
    padding:1rem;
    background:rgba(0,0,0,0.3) ;

}
@keyframes transformX{
    from { transform:translateX(-100%);background:none;}
    to {  transform:translateX(0%);}
}
.showAbout {
opacity:1;
animation: dropDown 2s ease-in-out;

}
.showAbout h1, .showAbout p{
    color:black;
}
.white{
    opacity:0.3;
    position:absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
}
.hideAbout {
opacity:0;
}
@keyframes dropDown {
    from { transform:translateY(-50%);opacity:0;}
    to {  transform:translateY(0%); opacity:1}
}
@media screen and (max-width:900px){
    .showStack,.hide{
    width:100%;
    }

}
@media screen and (max-width:900px){
    .showStack,.hide{
        width:100%;
        }
        .showPhrase{
            transform:scale(0.8);
            top:15%;
        }
        @keyframes transformX{
            from { transform:translateX(-100%) scale(0.8);background:none;}
            to {  transform:translateX(0%) scale(0.8);}
        }
}