*{margin:0;padding:0;box-sizing:border-box}


.message {
    position:absolute;
    animation: appear 16s ease-in-out;
}

.credit {
    color:white;
    position:absolute;
    top:90%;
    left:10%;
    animation: appearNow 10s ease-in-out;

}

@keyframes appear {
    from {opacity:0.6;}
    20% {opacity:1;}
    100% {opacity:0;}
}
@keyframes appearNow {
    from {opacity:0.6;transform:translateX(-100%);}
    20% {opacity:1;transform:translateX(-50%);}
    100% {opacity:1;transform:translateX(0%);}
}
@media screen and (max-width:600px){
    .credit {
        top:90%;
        left:0%;
    
    }
}