*{box-sizing:border-box;padding:0;margin:0;}

:root{
    --font-family:"'Philosopher', sans-serif";
    --font-family2: "'Philosopher', sans-serif";
    --background-image-2:linear-gradient(to left, blue, rgb(188, 243, 116) 50%,rgb(236, 28, 13));
  }


.fontStyleCt{
    -webkit-background-clip:text;
    background-clip:text;
    background-image:var(--background-1111);
    background-size:50% 50%;
    -webkit-text-stroke:1px white;
    color:transparent ;
}
.childGrid > h1{
    font-family: var(--font-family);
    color:linear-gradient(#e66465, #9198e5);

}
.childGrid > h2{
    font-family: var(--font-family);
    color:white;
}
.childGridForm{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    gap:10px;
}
.childGridForm > div{
    margin:auto;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    gap:10px;
}
.childGridForm >form{
    margin:auto;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    gap:10px;
    
    
}
.contentInput{
    display:flex;
    flex-direction:column;
    margin:auto;
    justify-self:center;
    align-self:center;
    
    margin:10px;
}
.childGridForm >form> div,.childGridForm >form>label{
    color:white;
    /* border:1px solid red; */
}
.hr_line{
height:3px;
background-image:var(--background-image-2);
width:100%;
margin:3rem auto;
}
/* ---------ChatWithUs.js-START---------- */
.chatWithUsOut{
    position:relative;
    justify-content:center;
    align-items:center;
}
.chatWithUsIn{
    justify-content:center;
    align-items:center;
}
/* -------ANCHOR START------- */
a[href ="tel"],a[href ="mailto"]{
    position:relative;
    color:purple;
    border-left:1px solid white;
    padding:5px;
    font-family: var(--font-family);
}
a[href ="tel"]>h1,a[href ="mailto"]>h1{
font-size:150%;
transition:all 0.8s ease-in-out;
}
a[href ="tel"]>h1:hover,a[href ="mailto"]>h1:hover{
font-size:160%;
color:blue;
transition:all 0.8s ease-in-out;
}
a[href ="tel"]:before{
    position:absolute;
    text-align:center;
    vertical-align: middle;
    padding-top:5px;
    border-radius:50%;
    transform:translate(20%,-160%);
}
a[href ="mailto"]:before{
    position:absolute;
    text-align:center;
    vertical-align: middle;
    padding-top:5px;
    border-radius:50%;
    transform:translate(20%,-180%);
}
a[href ="tel"]:hover:before{
content:"Call me";
font-size:1.5rem;
width:120px;
height:30px;
box-shadow:1px 1px 6px 1px white;
animation: showUp 1s ease-in-out;
}
a[href ="mailto"]:hover:before{
content:"send me an email";
font-size:1.5rem;
width:160px;
height:60px;
box-shadow:1px 1px 6px 1px white;
animation: email 1s ease-in-out;
}
@keyframes showUp {
    from {transform:translate(0px,0px);opacity:0;}
    to {transform:translate(20%,-160%);opacity:1;}
}
@keyframes email {
    from {transform:translate(0px,0px);opacity:0;}
    to {transform:translate(20%,-180%);opacity:1;}
}
/* -------ANCHOR END------- */
.contactMsg{
    position:absolute;
    top:0%;
    justify-content:flex-start;
    align-items:center;
    background-color:rgba(0,0,0,0.8);
    z-index:2000;
    padding:1rem;
    height:auto;
}
.contactMsg >h1{
    color:white;
    font-family: var(--font-family);
}
.message{
    margin:auto;
    margin:1rem auto;
    margin-left:1rem;
}
.message >h1{

    color:white;
    font-family: var(--font-family);
    margin:1rem auto;
}
/* ---------ChatWithUs.js-END---------- */
/* ---------BioBanner-END---------- */
.gridChild > div >div{
    margin:auto !important;
}
.gridChild >div> div > h2,.gridChild >div> div > h3{
    margin:1rem auto !important;
    color:white;
    font-family:var(--font-family);
   
}

/* ---------BioBanner.js-END---------- */